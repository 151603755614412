import { Experience as ExperienceComponent } from '@ninetailed/experience.js-next';
import isEmpty from 'lodash/isEmpty';
import { FC, useEffect, useMemo } from 'react';

import { ModuleScripts } from '@components/common/types/ModuleScripts';
import { mapExperiences } from '@components/screen/mapExperience';
import { NtConfigurable } from '@components/screen/types';

interface Props {
  customScripts: NtConfigurable<ModuleScripts & { id: string }>[] | null;
}

// to ensure `<script>` tag is executed on SPA redirect or fresh hit (reload/direct hit)
const DangerousScript: FC<{ id: string; script: string }> = ({ id, script }) => {
  // a bit of a conundrum here, useLayoutEffect (execute before component render) vs useEffect (after render)
  // will go with useEffect for now (I'm assuming the injected script will need to interact with rendered components)
  // possibility of screen flickers
  useEffect(() => {
    const scriptId = `custom-script-${id}`;
    if (script) {
      const scriptEl = document.createElement('script');
      scriptEl.setAttribute('id', scriptId);
      scriptEl.setAttribute('onload', 'true');
      // should ideally use this, but not yet supported on a lot of browsers
      // scriptEl.setHTMLUnsafe(script);
      scriptEl.innerHTML = `try {
        eval(${JSON.stringify(script)});
      } catch {}`;

      document.head.append(scriptEl);
    }

    return () => {
      // clean up
      document.getElementById(scriptId)?.remove();
    };
  }, [id, script]);

  return null;
};

const CustomScriptAndStyle: FC<Pick<ModuleScripts, 'script' | 'style'> & { id: string }> = ({ id, script, style }) => (
  <>
    {!!script && <DangerousScript id={id} script={script} />}
    {!!style && (
      // eslint-disable-next-line react/no-danger
      <style dangerouslySetInnerHTML={{ __html: style }} />
    )}
    {/* dummy element to ensure ninetailed tracking triggers */}
    <div className="ninetailed-marker" style={{ height: 0, width: 0, margin: 0, padding: 0 }} />
  </>
);

const CustomScripts: FC<Props> = ({ customScripts }) => {
  const mappedScripts = useMemo(
    () =>
      customScripts?.map((script) => ({
        experiences: mapExperiences(script.nt_experiences),
        ...script,
      })),
    [customScripts]
  );

  return (
    <>
      {mappedScripts?.map(({ id, script, style, experiences }) =>
        isEmpty(experiences) ? (
          <CustomScriptAndStyle key={id} id={id} script={script} style={style} />
        ) : (
          <ExperienceComponent
            key={id}
            id={id}
            experiences={experiences}
            component={CustomScriptAndStyle}
            {...{ style, script }}
            passthroughProps={{
              id,
              script,
              style,
            }}
            loadingComponent={() => null}
          />
        )
      )}
    </>
  );
};

export default CustomScripts;
