import 'swiper/css';

import { config, library } from '@fortawesome/fontawesome-svg-core';
import { faChevronDown, faChevronLeft, faChevronRight, faChevronUp } from '@fortawesome/pro-light-svg-icons';
import { Ninetailed } from '@ninetailed/experience.js';
import { NinetailedProvider } from '@ninetailed/experience.js-next';
import { NinetailedContentsquarePlugin } from '@ninetailed/experience.js-plugin-contentsquare';
import { NinetailedGoogleTagmanagerPlugin } from '@ninetailed/experience.js-plugin-google-tagmanager';
import { NinetailedInsightsPlugin } from '@ninetailed/experience.js-plugin-insights';
import { NinetailedPreviewPlugin } from '@ninetailed/experience.js-plugin-preview';
import { getHtmlLangFromLocale } from '@services/helpers/storefront-html-lang-mapping';
import { isNinetailedPreviewable } from '@services/ninetailed/isNinetailedPreviewable';
import { polyfillCountryFlagEmojis } from 'country-flag-emoji-polyfill';
import type { AppProps } from 'next/app';
import { useRouter } from 'next/router';
import { appWithTranslation } from 'next-i18next';
import i18nConfig, { i18n } from 'next-i18next.config';
import { useEffect, useMemo } from 'react';

import CustomScripts from '@components/common/CustomScripts';
import ErrorBoundary from '@components/common/Error/ErrorBoundary';
import Head from '@components/common/Head/Head';
import ToastContainer from '@components/common/ToastContainer/ToastContainer';
import { ManagedUIContext } from '@components/ui/context';
import { CommerceProvider } from '@framework';
import { getNinetailedClientConfig } from '@lib/get-client-config';
import { pageView } from '@lib/gtag';
import { stripSensitiveQueryParams } from '@lib/query-string';
import { FCWithChildren } from '@lib/types/react-utilities';

import '@fortawesome/fontawesome-svg-core/styles.css';
import '@assets/main.scss';
import '@assets/chrome-bug.css';
// css module + onMouseEnter/onMouseLeave + setState is sluggish
// hence using normal scss here
import '@assets/column-hover.scss';
import 'react-toastify/dist/ReactToastify.css';

config.autoAddCss = false;
config.autoA11y = true;

library.add(faChevronUp, faChevronDown, faChevronLeft, faChevronRight);

const Noop: FCWithChildren = ({ children }) => <>{children}</>;

const cleanPath = (str: string) => str.replace(/\/+$/, '');

const ntConfig = getNinetailedClientConfig();
const ntApiKey = ntConfig.apiKey ?? '';
const ntEnvironment = ntConfig.environment ?? 'development';
const isNtPreviewable = isNinetailedPreviewable(ntEnvironment);

polyfillCountryFlagEmojis();

function MyApp({ Component, pageProps }: AppProps<any>) {
  const router = useRouter();
  const { locale = i18n.defaultLocale } = router;
  const Layout = (Component as any).Layout || Noop;
  const isErrorPage: boolean = (Component as any).isErrorPage ?? false;

  // handle both standalone contentful page and dynamic screens
  let moduleSeo = (pageProps?.page ? pageProps.page.moduleSeo : pageProps?.content?.moduleSeo) ?? {};
  if (pageProps?.seo) {
    moduleSeo = { ...moduleSeo, ...pageProps.seo };
  }

  useEffect(() => {
    document.body.classList?.remove('loading');
  }, []);

  useEffect(() => {
    // track page view only on initial load, we don't add router as a dependency
    // because we want this only called once but we need router for asPath
    pageView(`${locale && locale !== i18n.defaultLocale ? `/${locale}` : ''}${router.asPath.split('?')[0]}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const mappedLang = getHtmlLangFromLocale(locale);
    const langObserver = new MutationObserver(() => {
      if (document.documentElement.lang !== mappedLang) {
        document.documentElement.lang = mappedLang;
      }
    });
    langObserver.observe(document.documentElement, {
      attributeFilter: ['lang'],
    });

    return () => {
      langObserver.disconnect();
    };
  }, [locale]);

  useEffect(() => {
    // track page view on next router path redirect
    // workaround to avoid triggering pageviews on shallow routing with the same pathname
    // `shallow` flag returns true for static optimization
    // https://github.com/vercel/next.js/issues/29713
    // https://github.com/vercel/next.js/issues/3322
    let handleRouteChange: (url: string, { shallow }: { shallow: boolean }) => void;
    if (router.isReady) {
      let lastRoute = `${locale && locale !== i18n.defaultLocale ? `/${locale}` : ''}${router.asPath.split('?')[0]}`;
      handleRouteChange = (url, { shallow }) => {
        const pathname = url.split('?')[0];
        // remove trailing slash when comparing
        const diffPath = cleanPath(lastRoute) !== cleanPath(pathname);
        if (!shallow || (shallow && diffPath)) {
          pageView(pathname);
          lastRoute = pathname;
        }
      };
      router.events.on('routeChangeComplete', handleRouteChange);
    }
    return (): void => {
      if (handleRouteChange) {
        router.events.off('routeChangeComplete', handleRouteChange);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.events, router.isReady]);

  const ninetailed = useMemo(
    () =>
      new Ninetailed(
        {
          clientId: ntApiKey,
          environment: ntEnvironment,
        },
        {
          plugins: [
            new NinetailedGoogleTagmanagerPlugin({
              template: {
                ninetailed_audience_name: '{{ audience.name }}',
              },
            }),
            new NinetailedInsightsPlugin(),
            // default properties send to CS are experienceId, variant name, audienceId, componentId
            new NinetailedContentsquarePlugin(),
            ...(isNtPreviewable
              ? [
                  new NinetailedPreviewPlugin({
                    experiences: pageProps?.ninetailed?.experiences ?? [],
                    audiences: pageProps?.ninetailed?.audiences ?? [],
                  }),
                ]
              : []),
          ],
          buildClientContext: () => ({
            url: stripSensitiveQueryParams(window.location.href),
            referrer: document.referrer,
            locale: navigator.languages?.length ? navigator.languages[0] : navigator.language,
            userAgent: navigator.userAgent,
            document: {
              title: document.title,
            },
          }),
        }
      ),
    [pageProps?.ninetailed]
  );

  return (
    <NinetailedProvider ninetailed={ninetailed}>
      <Head moduleSeo={moduleSeo} isErrorPage={isErrorPage} />
      <ErrorBoundary>
        <ManagedUIContext product={pageProps.product}>
          <CommerceProvider locale={locale}>
            <>
              <CustomScripts customScripts={pageProps?.customScripts || null} />
              <Layout pageProps={pageProps} isErrorPage={isErrorPage}>
                <Component {...pageProps} isErrorPage={isErrorPage} />
              </Layout>
            </>
          </CommerceProvider>
          <ToastContainer />
        </ManagedUIContext>
      </ErrorBoundary>
    </NinetailedProvider>
  );
}

const TranslatedApp = appWithTranslation(MyApp, {
  ...i18nConfig,
  // default value if no key is found
  overloadTranslationOptionHandler: () => ({ defaultValue: '' }),
});

export default function WrappedApp(props: AppProps) {
  return <TranslatedApp {...props} />;
}
