import cn from 'classnames';
import React, { CSSProperties, FC, forwardRef, HTMLAttributes, JSXElementConstructor, Ref } from 'react';

import getTailwindConfig from '@lib/get-tailwind-config';

import s from './Text.module.scss';

interface Props extends HTMLAttributes<HTMLDivElement> {
  id?: string;
  variant?: Variant;
  weight?: Weight;
  color?: string;
  className?: string;
  style?: CSSProperties;
  children?: React.ReactNode | any;
  html?: string;
  asElement?: React.ComponentType<any> | string;
  ref?: Ref<any>;
}

export type Weight =
  | 'thin'
  | 'extralight'
  | 'light'
  | 'normal'
  | 'medium'
  | 'semibold'
  | 'bold'
  | 'extrabold'
  | 'black';

export type Variant =
  | 'heading-1'
  | 'heading-2'
  | 'heading-3'
  | 'heading-4'
  | 'heading-5'
  | 'text-1'
  | 'text-2'
  | 'text-3'
  | 'text-4'
  | 'inline'
  | 'xxsmall'
  | 'xsmall'
  | 'medium'
  | 'base'
  | 'large'
  | 'base-bold'
  | 'header-5'
  | 'header-5-book'
  | 'header-4'
  | 'header-3'
  | 'header-2'
  | 'header-1'
  | 'eyebrow';

const componentsMap: {
  [P in Variant]: React.ComponentType<any> | string;
} = {
  'heading-1': 'h1',
  'heading-2': 'h2',
  'heading-3': 'h3',
  'heading-4': 'h4',
  'heading-5': 'h5',
  'text-1': 'p',
  'text-2': 'p',
  'text-3': 'p',
  'text-4': 'p',
  inline: 'span',
  xxsmall: 'p',
  xsmall: 'p',
  medium: 'p',
  base: 'p',
  large: 'p',
  'base-bold': 'p',
  'header-5': 'h5',
  'header-5-book': 'h5',
  'header-4': 'h4',
  'header-3': 'h3',
  'header-2': 'h2',
  'header-1': 'h1',
  eyebrow: 'p',
};

const fontWeightMap = getTailwindConfig('theme.fontWeight');

function getComponent(
  variant: Variant,
  asElement?: React.ComponentType<any> | string
): React.ComponentType<any> | string {
  if (asElement) {
    return asElement;
  }
  return componentsMap[variant];
}

function getClassName(variant: Variant): string {
  return variant.replace(/-/gi, '');
}

function isAsElementSafeForSetHtml(asElement: React.ComponentType<any> | string = 'div') {
  return ['div', 'ul', 'span'].includes(asElement as string);
}

const Text: FC<Props> = forwardRef((props, ref) => {
  const { style, color, weight, className = '', variant = 'text-4', children, html, asElement, ...rest } = props;

  const componentForSetHtml = (isAsElementSafeForSetHtml(asElement) && asElement) || 'div';

  const Component: JSXElementConstructor<any> | React.ReactElement<any> | React.ComponentType<any> | string = html
    ? // to handle dangerouslySetInnerHtml in SSR https://flaviocopes.com/react-fix-dangerouslysetinnerhtml-did-not-match/
      componentForSetHtml
    : getComponent(variant, asElement);

  const htmlContentProps = html
    ? {
        dangerouslySetInnerHTML: { __html: html },
      }
    : {};

  return (
    <Component
      ref={ref}
      className={cn(s[getClassName(variant)], className, {
        [s.html]: !!htmlContentProps.dangerouslySetInnerHTML,
      })}
      style={{ ...style, fontWeight: weight && fontWeightMap[weight], color }}
      {...htmlContentProps}
      {...rest}
    >
      {children}
    </Component>
  );
});

Text.displayName = 'Text';

export default Text;
