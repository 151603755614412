import { useTranslation } from 'next-i18next';
import { FC } from 'react';

import Text from '@components/ui/Text/Text';

import s from './Error.module.scss';

interface ErrorProps {
  statusCode: number;
  title?: string;
  subtitle?: string;
}

const Error: FC<ErrorProps> = ({ statusCode, title, subtitle }) => {
  const { t } = useTranslation(['common']);

  return (
    <div className={s.root}>
      <Text className="mb-5" variant="heading-1">
        {statusCode} {t(title ?? 'common:pageError.generic')}
      </Text>
      <Text color="var(--text-secondary-3)" html={t(subtitle ?? 'common:pageError.subtitle')} />
    </div>
  );
};

export default Error;
